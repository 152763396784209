import { object, number } from 'yup'

const confirmCodeSchema = object().shape({
    code: number()
        .typeError('O campo deve conter apenas números')
        .required('Preencha o campo com seu código')
        .min(0, 'O campo não pode ser vazio')
})

export default confirmCodeSchema

