import io from 'socket.io-client'
// import ss from 'socket.io-stream'
import { getToken } from '../api/auth'
// import fs from 'fs';

export function connect() {
    return io(process.env.REACT_APP_API_URL || 'http://localhost:3000', {
        path: '/ws',
        extraHeaders: {
            Authorization: `Bearer ${getToken()}`
        }
    });
}

// export function streamEmit(event, filename, options) {
//     var stream = ss.createStream();
//     ss(connect()).emit(event, stream, options);
//     fs.createReadStream(filename).pipe(stream);
// }

export function disconnect() {
    // io(process.env.REACT_APP_API_URL || 'http://localhost:3000', {
    //     extraHeaders: {
    //         Authorization: `Bearer ${getToken()}`
    //     }
    // }).disconnect();
}