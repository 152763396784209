import { string, object } from 'yup'

const loginSchema = object().shape({
    email: string()
        .required('Preencha o campo com seu e-mail')
        .email('E-mail inválido'),
    password: string()
        .required('Preencha o campo com sua senha')
        .min(6, 'A senha deve conter no mínimo 6 dígitos'),
})

export default loginSchema
