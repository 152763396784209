export const sugestions = [
    'No meu velório, por favor, alguém levante e grite "Essa pessoa não gostava de metade de vocês", Thank you!',
    'Sua mensagem foi visualizada, ignorada, humilhada e esquecida com sucesso.',
    'Não sou capaz de opinar.',
    'Tem gente que é igual nuvem, quando some o dia fica lindo.',
    'Não tenho energia para fingir gostar de você hoje.',
    'Sua opinião é igual anúncio do YouTube: ignoro em 5 segundos.',
    'A luz viaja mais rápido que o som. Por isso, algumas pessoas parecem brilhantes até você ouvi-las falando.',
    'Você me olha com a cara feia, mas infelizmente não vou poder retribuir porque a minha é linda.',
    'Ver gente que eu odeio se ferrando na vida não tem preço.',
    'Alguém sabe qual é o dia que se comemora a falsidade? Tenho muita gente pra homenagear.',
    'A vida é feita de escolhas e eu escolhi te ignorar.',
    'Sou bilíngue, falo português e várias merdas.',
    'Se for me dar gelo, não esquece da vodka.',
    'Meu sobrinho de 5 anos faz melhor que você.',
    'Cala boca, você fuma.',
    'Se você quer mesmo ser visto, pinta a bunda de vermelho e sobe na torre da matriz.',
    'Só um fininho.',
    'Se não aguenta bebe leite.',
    'Vai toma no cool.',
]