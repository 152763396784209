import { useCallback } from "react"

function useYupValidation(validationSchema) {
    return useCallback(
        async (data) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                })

                return {
                    values,
                    errors: {},
                }
            } catch (errors) {
                const newError = errors
                return {
                    values: {},
                    errors: newError.inner.reduce(
                        (
                            allErrors,
                            currentError,
                        ) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? 'validation',
                                message: currentError.message,
                            },
                        }),
                        {},
                    ),
                }
            }
        },
        [validationSchema],
    )
}

export default useYupValidation