import { useEffect, useState,useCallback } from "react";
import { useForm } from "react-hook-form";
import { api } from "../../service";
import ButtonForm from "../../componentes/buttons/ButtonForm";
import { useYupValidation } from "../../utils";
import questionSchema from "../../utils/yup/questionSchema";
import { toast } from "react-toastify";
import Timer from "../../componentes/timer/Timer";
import  { useRegressiveTimer } from "../../hooks/useTimer";
import { useNavigate } from "react-router-dom";
import { differenceInSeconds } from "date-fns";

export default function Question({ setAnsweringMode }) {
  const [question, setQuestion] = useState();
  const [loading, setLoading] = useState(false);
  const [secondsAnswered, setSecondsAnswerd] = useState();
  const [isAnwsered, setIsAnwsered] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: useYupValidation(questionSchema),
  });
  useEffect(() => {
    api.get("/question/").then((response) => {
      setQuestion(response.data);
    });
  }, []);
  function paintAnswer(correctAnswer, wrongAnswer) {
    const divCorrectAnswer = document.getElementById(`option-${correctAnswer}`);
    divCorrectAnswer.classList.add(
      `container-wrapper-options-option--correct-option`
    );
    if (wrongAnswer >= 0) {
      const divWrongAnswer = document.getElementById(`option-${wrongAnswer}`);
      divWrongAnswer.classList.add(
        `container-wrapper-options-option--wrong-option`
      );
    }
  }
  function submit(values) {
    if (!values.answer || isAnwsered) {
      return;
    }
    setLoading(true);
    api
      .patch(`/question/${question._id}`, { answer: values.answer })
      .then((response) => {
        if (response.data) {
          const correctAnswer = response.data.answer;
          const correctAnswerIndex = question.options.findIndex(
            (option) => option === correctAnswer
          );
          if (correctAnswer === values.answer) {
            paintAnswer(correctAnswerIndex);
          } else {
            paintAnswer(
              correctAnswerIndex,
              question.options.findIndex((option) => option === values.answer)
            );
          }
        }
        setLoading(false);
        setSecondsAnswerd(seconds);
        setIsAnwsered(true)
        toast.success('Pergunta Respondida!',{
          position: toast.POSITION.BOTTOM_RIGHT,
          className:'toast-success'
        })
        setTimeout(() => {
          navigate('/')
        }, 5000)
      })
      .catch((err) => {
        toast('Perdeu playboy!');
        setTimeout(() => {
          navigate('/')
        }, 2000)
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const getRegressiveTimer = useCallback(() => {
    let timer = 30
    if (question && question.readedAt) {
      const time = differenceInSeconds(new Date(),new Date(question.readedAt));
      timer = 30 - time
    }
    return timer
  }, [question]);
  const currentAnswer = watch("answer");
  const { seconds } = useRegressiveTimer(getRegressiveTimer());
  useEffect(() => {
    if (seconds === 0 && !secondsAnswered) {
      if (currentAnswer) {
        submit({ answer: currentAnswer });
      } else {
        toast.error('Tempo esgotado!', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
        setAnsweringMode(false);
      }
    }
     //eslint-disable-next-line
  }, [seconds, currentAnswer, navigate]);

  return (
    // <div className="app-main">
    <div
      // style={{
      //   display: "flex",
      //   padding: "4rem",
      //   // paddingInline: "10rem",
      //   marginInline: "5rem",
      //   color: "white",
      //   flex: 1,
      // }}
      className="container"
    >
      {question && (
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginTop: '40px' }}>
              <span>Leia a questão e escolha a resposta mais correta</span>
              <h2>{question.question}</h2>
            </div>
            <Timer display={{ Segundos: secondsAnswered ?? seconds }} />
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <div className={"container-wrapper-options"}>
              {question.options.map((option, index) => {
                return (
                  <div
                    className="container-wrapper-options-option"
                    style={{ cursor: isAnwsered ? 'not-allowed' : 'pointer' }}
                    id={`option-${index}`}
                    key={option}
                    onClick={() => {
                      if (!isAnwsered) {
                        setValue("answer", option);
                      }
                    }}
                  >
                    <div>
                      <input
                        {...register("answer")}
                        type="radio"
                        value={option}
                        style={{
                          border: 0,
                          width: "1.1rem",
                          height: "1.2rem",
                          marginLeft: "1rem",
                        }}
                      />
                    </div>
                    <div style={{ paddingInline: "1rem" }}>
                      <span>{option}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                width: "100%",
                display: errors.answer ? "flex" : "hidden",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "#FC8181" }}>{errors?.answer?.message}</p>
            </div>
            <div
              style={{
                display: "flex",
                // marginTop: "3rem",
                justifyContent: "right",
              }}
            >
              <ButtonForm
                style={{ cursor: isAnwsered ? 'not-allowed' : 'pointer' }}
                loading={loading}
                type="submit"
                placeholder="Enviar"
                success={!loading}
                mantainCheckButton
              />
            </div>
          </form>
        </div>
      )}
    </div>
    // </div>
  );
}
