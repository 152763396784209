import { Navigate, Route, Routes as Router } from "react-router-dom";
import { isAuthenticated } from "../service";
import { AuthRoute, Menu, PublicRoute } from "../componentes";
import {
  Chat,
  FirstAccess,
  Login,
  Config,
  Game,
  Inbox,
  Inboxmessage,
  RecoverPass,
  ChangePass,
  Register,
  ConfirmCode,
  Quizz,
} from "../views";
import Question from "../views/quizz/Question";

export default function Routes() {
  return (
    <Router>
      <Route
        path="/primeiro-acesso/:id"
        element={
          <PublicRoute>
            <FirstAccess />
          </PublicRoute>
        }
      />
      <Route
        path="/"
        element={
          <Navigate
            replace
            state={{ from: "/" }}
            to={isAuthenticated() ? "/chat" : "/login"}
          />
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/recuperar-senha"
        element={
          <PublicRoute>
            <RecoverPass />
          </PublicRoute>
        }
      />
      <Route
        path="/trocar-senha"
        element={
          <PublicRoute>
            <ChangePass />
          </PublicRoute>
        }
      />
      <Route
        path="/registrar"
        element={
          <PublicRoute>
            <Register />
          </PublicRoute>
        }
      />
      <Route
        path="/confirmar-codigo"
        element={
          <PublicRoute>
            <ConfirmCode />
          </PublicRoute>
        }
      />
      <Route
        path="/chat"
        element={
          <AuthRoute>
            <Menu>
              <Chat />
            </Menu>
          </AuthRoute>
        }
      />
      <Route
        path="/tiro-cara"
        element={
          <AuthRoute>
            <Menu>
              <Game />
            </Menu>
          </AuthRoute>
        }
      />
      <Route
        path="/config"
        element={
          <AuthRoute>
            <Menu>
              <Config />
            </Menu>
          </AuthRoute>
        }
      />
      <Route
        path="/cartinhas"
        element={
          <AuthRoute>
            <Menu>
              <Inbox />
            </Menu>
          </AuthRoute>
        }
      />
      <Route
        path="/cartinhas/enviar"
        element={
          <AuthRoute>
            <Menu>
              <Inboxmessage />
            </Menu>
          </AuthRoute>
        }
      />
      {/* <Route
        path="/quiz"
        element={
          <AuthRoute>
            <Menu>
              <Quizz />
            </Menu>
          </AuthRoute>
        }
      />
      <Route
        path="/quiz/question"
        element={
          <AuthRoute>
            <Menu>
              <Question />
            </Menu>
          </AuthRoute>
        }
      /> */}
    </Router>
  );
}
