const TOKEN_KEY = 'token'
const USUARIO = 'user'
const REFRESHE_TOKEN = 'refreshToken'
const THEME = 'theme'
const JAIL = 'jail'

export function setTheme(theme) {
    localStorage.setItem(THEME, theme)
}

export function getToken() {
    return localStorage.getItem(TOKEN_KEY)
}

export function getUser() {
    return localStorage.getItem(USUARIO)
}

export function login(obj) {
    localStorage.setItem(TOKEN_KEY, obj.token)
    localStorage.setItem(REFRESHE_TOKEN, obj.refreshToken)
    localStorage.setItem(USUARIO, JSON.stringify(obj.user))
}

export function logout() {
    const theme = localStorage.getItem('theme')
    localStorage.clear()
    setTheme(theme ?? 'dark')
    window.location.href = '/login'
}

export function isAuthenticated() {
    return localStorage.getItem(TOKEN_KEY) !== null
}

export function setJailLocal(bool) {
    return localStorage.setItem(JAIL, bool)
}

export function getJail(bool) {
    return localStorage.setItem(JAIL, bool)
}