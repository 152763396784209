import OneSignal from 'react-onesignal';
import { getUser } from '../api/auth';

export async function runOneSignal() {
    try {
        await OneSignal.init({
            allowLocalhostAsSecureOrigin: true,
            appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
        });
        OneSignal.showSlidedownPrompt();

        let user = JSON.parse(getUser());
        OneSignal.setExternalUserId(user.id)
    } catch (error) {
        console.log(error)
    }
}