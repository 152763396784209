import { object, string} from 'yup';

const firstAccessSchema = object().shape({
    recipientId: object()
        .required('Campo obrigatório'),
    subject: string()
        .required('Campo obrigatório'),
    message: string()
        .required('Campo Obrigatório')
        .min(2, 'A mensagem deve conter no mínimo 2 caracteres')
})

export default firstAccessSchema
