import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MenuContext } from "../../context";
import { api, errorHandler, getUser, logout, setTheme } from "../../service";

export default function Menu(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const [theme, setThemeMenu] = React.useState(localStorage.getItem("theme"));
  const [user] = React.useState(JSON.parse(getUser()));
  const [avatar, setAvatar] = React.useState(user.avatar);
  // const [rank, setRank] = React.useState(0)
  const [palmitoCoins, setPalmitoCoins] = React.useState(0);

  const valueContext = React.useMemo(
    () => ({
      changeTheme: async (data) => {
        setThemeMenu(data);
        setTheme(data);
      },
      changeAvatar: async (data) => {
        setAvatar(data);
      },
      // changeRank: async (data) => {
      //     setRank(data)
      // },
      changePalmitoCoins: async (data) => {
        setPalmitoCoins(data);
        localStorage.setItem("points", data);
      },
    }),
    []
  );

  React.useEffect(() => {
    api
      .get(`/users/my-rank-coins`)
      .then((res) => {
        // setRank(res.data.rank)
        if (res.data.palmitoCoins) {
          setPalmitoCoins(res.data.palmitoCoins);
          localStorage.setItem("points", res.data.palmitoCoins);
        }
      })
      .catch((err) => {
        toast(errorHandler(err).erro);
      });
  }, []);

  return (
    <MenuContext.Provider value={valueContext}>
      <div className="body-chat" data-theme={theme}>
        <div className="app-container container">
          {/* primeiro tema para a festa junina */}
          {/* <div style={{
                        width: '100%',
                    }}>
                        <img
                            src="/bandeirinhas.png"
                            alt="bandeiras"
                            style={{ width: "100%", height: 40, pointerEvents: 'none', zIndex: 1, objectFit: 'cover' }}
                        />
                    </div> */}
          <div className="app-left">
            <div className="app-left-header">
              <div className="app-logo">
                <img src="/cavalo-mask.png" alt="logo" />
              </div>
              <h1>Correio Cavalo Louco</h1>
            </div>
            <div className="app-profile-box">
              <img
                src={
                  avatar ||
                  "https://media1.giphy.com/media/xUPGcANuYN4yk78oH6/source.gif"
                }
                alt="profile"
              />
              <div className="app-profile-box-name">
                {user.name}
                <button
                  className="app-setting"
                  type="button"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/config")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="feather feather-settings"
                    viewBox="0 0 24 24"
                  >
                    <defs />
                    <circle cx="12" cy="12" r="3" />
                    <path d="M19.4 15a1.65 1.65 0 00.33 1.82l.06.06a2 2 0 010 2.83 2 2 0 01-2.83 0l-.06-.06a1.65 1.65 0 00-1.82-.33 1.65 1.65 0 00-1 1.51V21a2 2 0 01-2 2 2 2 0 01-2-2v-.09A1.65 1.65 0 009 19.4a1.65 1.65 0 00-1.82.33l-.06.06a2 2 0 01-2.83 0 2 2 0 010-2.83l.06-.06a1.65 1.65 0 00.33-1.82 1.65 1.65 0 00-1.51-1H3a2 2 0 01-2-2 2 2 0 012-2h.09A1.65 1.65 0 004.6 9a1.65 1.65 0 00-.33-1.82l-.06-.06a2 2 0 010-2.83 2 2 0 012.83 0l.06.06a1.65 1.65 0 001.82.33H9a1.65 1.65 0 001-1.51V3a2 2 0 012-2 2 2 0 012 2v.09a1.65 1.65 0 001 1.51 1.65 1.65 0 001.82-.33l.06-.06a2 2 0 012.83 0 2 2 0 010 2.83l-.06.06a1.65 1.65 0 00-.33 1.82V9a1.65 1.65 0 001.51 1H21a2 2 0 012 2 2 2 0 01-2 2h-.09a1.65 1.65 0 00-1.51 1z" />
                  </svg>
                </button>
              </div>

              <div className="app-profile-box-info">
                {/* <div className="app-profile-box-info-rank" title="Ranking"> */}
                {/* {rank || 0}º */}
                {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon-rank" viewBox="0 0 512 512">
                                        <path d="M509.8 332.5l-69.89-164.3c-14.88-41.25-50.38-70.98-93.01-79.24c18-10.63 46.35-35.9 34.23-82.29c-1.375-5.001-7.112-7.972-11.99-6.097l-202.3 75.66C35.89 123.4 0 238.9 0 398.8v81.24C0 497.7 14.25 512 32 512h236.2c23.75 0 39.3-25.03 28.55-46.28l-40.78-81.71V383.3c-45.63-3.5-84.66-30.7-104.3-69.58c-1.625-3.125-.9342-6.951 1.566-9.327l12.11-12.11c3.875-3.875 10.64-2.692 12.89 2.434c14.88 33.63 48.17 57.38 87.42 57.38c17.13 0 33.05-5.091 46.8-13.22l46 63.9c6 8.501 15.75 13.34 26 13.34h50.28c8.501 0 16.61-3.388 22.61-9.389l45.34-39.84C511.6 357.7 514.4 344.2 509.8 332.5zM328.1 223.1c-13.25 0-23.96-10.75-23.96-24c0-13.25 10.75-23.92 24-23.92s23.94 10.73 23.94 23.98C352 213.3 341.3 223.1 328.1 223.1z" />
                                    </svg> */}
                {/* </div> */}

                <div
                  className="app-profile-box-info-coins"
                  title="PalmitoCoins"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon-coins"
                    viewBox="0 0 576 512"
                  >
                    <circle cx="255" cy="250" r="250" />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="7%"
                      y="10%"
                      width="75%"
                      height="75%"
                      viewBox="0 0 243.8 243.8"
                      xmlSpace="preserve"
                    >
                      <path d="m220.98 84.214h-6.054c-6.465-14.758-16.708-27.538-29.988-37.411l8.281-28.347c0.809-2.775-0.023-5.767-2.146-7.725-2.128-1.96-5.179-2.551-7.872-1.514l-47.099 17.994c-5.653-0.799-11.301-1.202-16.82-1.202-38.053 0-70.042 17.079-87.94 43.78-13.46-1.174-16.008-9.987-16.282-11.149-0.852-4.063-4.816-6.696-8.902-5.902-4.124 0.804-6.817 4.801-6.011 8.925 1.313 6.733 7.887 18.412 23.457 22.219-4.956 11.342-7.667 23.904-7.667 37.282 0 26.564 11.195 51.226 30.997 68.89-9.853 10.457-9.668 26.98 0.561 37.206 5.044 5.047 11.755 7.827 18.892 7.827s13.845-2.78 18.892-7.827l12.815-12.815c15.828 2.843 32.702 2.417 48.068-1.154l13.969 13.972c5.047 5.044 11.758 7.824 18.892 7.824s13.842-2.78 18.892-7.827c10.411-10.416 10.411-27.365-3e-3 -37.784l-2.747-2.744c8.357-8.273 15.062-17.994 19.734-28.618h6.079c12.587 0 22.826-10.241 22.826-22.826v-28.248c1e-3 -12.585-10.237-22.826-22.824-22.826zm7.608 51.064c0 4.195-3.414 7.609-7.609 7.609h-11.205c-3.183 0-6.029 1.978-7.134 4.963-4.679 12.628-12.754 23.954-23.356 32.755-1.646 1.367-2.643 3.366-2.742 5.504s0.708 4.22 2.222 5.734l8.387 8.387c4.484 4.484 4.484 11.783 3e-3 16.262-2.171 2.174-5.06 3.371-8.131 3.371s-5.963-1.197-8.134-3.368l-17.025-17.028c-1.446-1.446-3.388-2.229-5.379-2.229-0.675 0-1.352 0.089-2.019 0.271-8.636 2.379-17.784 3.584-27.183 3.584-7.505 0-14.933-0.789-22.08-2.343-2.536-0.553-5.169 0.223-6.997 2.057l-15.689 15.689c-2.171 2.171-5.06 3.368-8.134 3.368-3.071 0-5.96-1.197-8.134-3.368-4.484-4.487-4.484-11.781 0-16.265l5.384-5.384c1.565-1.562 2.371-3.731 2.209-5.937-0.16-2.206-1.276-4.233-3.051-5.549-20.109-14.933-31.641-37.601-31.641-62.2 0-45.57 37.888-79.935 88.132-79.935 5.387 0 10.933 0.444 16.483 1.321 1.314 0.211 2.66 0.068 3.901-0.408l34.652-13.236-5.514 18.869c-0.936 3.206 0.327 6.65 3.117 8.486 14.362 9.468 24.992 22.681 30.739 38.208 1.106 2.985 3.954 4.968 7.134 4.968h11.185c4.195 0 7.609 3.414 7.609 7.609v28.235z" />
                      <circle cx="166.67" cy="99.388" r="10.568" />
                      <rect
                        x="97.142"
                        y="55.216"
                        width="44.383"
                        height="15.217"
                      />
                    </svg>
                  </svg>
                  {(palmitoCoins || 0).toLocaleString("pt-BR")}
                </div>
              </div>
            </div>
            <ul className="chat-list-active chat-list-wrapper">
              <li
                className={`chat-list-item ${
                  location.pathname === "/chat" ? "active" : ""
                }`}
                onClick={() => navigate("/chat")}
              >
                <svg viewBox="0 0 512 512" className="icon-menu">
                  <path d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z" />
                </svg>

                <span className="chat-list-name">Chat</span>
              </li>
              {/* <li
                                className={`chat-list-item ${location.pathname === '/games' ? 'active' : ''}`}
                                onClick={() => navigate('/games')}
                            >
                                <svg
                                    viewBox="0 0 640 512"
                                    className="icon-menu"
                                >
                                    <path d="M448 64H192C85.96 64 0 149.1 0 256s85.96 192 192 192h256c106 0 192-85.96 192-192S554 64 448 64zM247.1 280h-32v32c0 13.2-10.78 24-23.98 24c-13.2 0-24.02-10.8-24.02-24v-32L136 279.1C122.8 279.1 111.1 269.2 111.1 256c0-13.2 10.85-24.01 24.05-24.01L167.1 232v-32c0-13.2 10.82-24 24.02-24c13.2 0 23.98 10.8 23.98 24v32h32c13.2 0 24.02 10.8 24.02 24C271.1 269.2 261.2 280 247.1 280zM431.1 344c-22.12 0-39.1-17.87-39.1-39.1s17.87-40 39.1-40s39.1 17.88 39.1 40S454.1 344 431.1 344zM495.1 248c-22.12 0-39.1-17.87-39.1-39.1s17.87-40 39.1-40c22.12 0 39.1 17.88 39.1 40S518.1 248 495.1 248z" />
                                </svg>
                                <span className="chat-list-name">Games</span>
                            </li> */}
              <li
                className={`chat-list-item ${
                  location.pathname === "/tiro-cara" ? "active" : ""
                }`}
                onClick={() => navigate("/tiro-cara")}
              >
                <svg className="icon-menu" viewBox="0 0 512 512">
                  <path d="M224 256C224 238.3 238.3 224 256 224C273.7 224 288 238.3 288 256C288 273.7 273.7 288 256 288C238.3 288 224 273.7 224 256zM256 0C273.7 0 288 14.33 288 32V42.35C381.7 56.27 455.7 130.3 469.6 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H469.6C455.7 381.7 381.7 455.7 288 469.6V480C288 497.7 273.7 512 256 512C238.3 512 224 497.7 224 480V469.6C130.3 455.7 56.27 381.7 42.35 288H32C14.33 288 0 273.7 0 256C0 238.3 14.33 224 32 224H42.35C56.27 130.3 130.3 56.27 224 42.35V32C224 14.33 238.3 0 256 0V0zM224 404.6V384C224 366.3 238.3 352 256 352C273.7 352 288 366.3 288 384V404.6C346.3 392.1 392.1 346.3 404.6 288H384C366.3 288 352 273.7 352 256C352 238.3 366.3 224 384 224H404.6C392.1 165.7 346.3 119.9 288 107.4V128C288 145.7 273.7 160 256 160C238.3 160 224 145.7 224 128V107.4C165.7 119.9 119.9 165.7 107.4 224H128C145.7 224 160 238.3 160 256C160 273.7 145.7 288 128 288H107.4C119.9 346.3 165.7 392.1 224 404.6z" />
                </svg>
                <span className="chat-list-name">Tiro na Cara</span>
              </li>
              <li
                className={`chat-list-item ${
                  location.pathname === "/cartinhas" ? "active" : ""
                }`}
                onClick={() => navigate("/cartinhas")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon-menu"
                  viewBox="0 0 512 512"
                >
                  <path d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z" />
                </svg>
                <span className="chat-list-name">Cartinhas</span>
              </li>
              {/* <li
                className={`chat-list-item ${
                  location.pathname === "/quiz" ? "active" : ""
                }`}
                onClick={() => navigate("/quiz")}
              >
                <svg
                  className="icon-menu"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32z" />
                </svg>
                <span className="chat-list-name">Quiz</span>
              </li> */}
            </ul>
            <li className="chat-list-item" onClick={logout}>
              <svg viewBox="0 0 512 512" className="icon-menu">
                <path d="M96 480h64C177.7 480 192 465.7 192 448S177.7 416 160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64C177.7 96 192 81.67 192 64S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256C0 437 42.98 480 96 480zM504.8 238.5l-144.1-136c-6.975-6.578-17.2-8.375-26-4.594c-8.803 3.797-14.51 12.47-14.51 22.05l-.0918 72l-128-.001c-17.69 0-32.02 14.33-32.02 32v64c0 17.67 14.34 32 32.02 32l128 .001l.0918 71.1c0 9.578 5.707 18.25 14.51 22.05c8.803 3.781 19.03 1.984 26-4.594l144.1-136C514.4 264.4 514.4 247.6 504.8 238.5z" />
              </svg>
              <span className="chat-list-name">Logout</span>
            </li>
          </div>
          <div className="nav-menu">
            <nav>
              <div className="logo">
                <div className="app-logo">
                  <img src="/cavalo-mask.png" alt="logo" />
                </div>
                <h1>Correio Cavalo Louco</h1>
              </div>

              <span className="itens">
                <li
                  className={`chat-list-item ${
                    location.pathname === "/chat" ? "active" : ""
                  }`}
                  onClick={() => navigate("/chat")}
                >
                  <svg viewBox="0 0 512 512" className="icon-menu">
                    <path d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z" />
                  </svg>
                </li>
                <li
                  className={`chat-list-item ${
                    location.pathname === "/tiro-cara" ? "active" : ""
                  }`}
                  onClick={() => navigate("/tiro-cara")}
                >
                  <svg className="icon-menu" viewBox="0 0 512 512">
                    <path d="M224 256C224 238.3 238.3 224 256 224C273.7 224 288 238.3 288 256C288 273.7 273.7 288 256 288C238.3 288 224 273.7 224 256zM256 0C273.7 0 288 14.33 288 32V42.35C381.7 56.27 455.7 130.3 469.6 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H469.6C455.7 381.7 381.7 455.7 288 469.6V480C288 497.7 273.7 512 256 512C238.3 512 224 497.7 224 480V469.6C130.3 455.7 56.27 381.7 42.35 288H32C14.33 288 0 273.7 0 256C0 238.3 14.33 224 32 224H42.35C56.27 130.3 130.3 56.27 224 42.35V32C224 14.33 238.3 0 256 0V0zM224 404.6V384C224 366.3 238.3 352 256 352C273.7 352 288 366.3 288 384V404.6C346.3 392.1 392.1 346.3 404.6 288H384C366.3 288 352 273.7 352 256C352 238.3 366.3 224 384 224H404.6C392.1 165.7 346.3 119.9 288 107.4V128C288 145.7 273.7 160 256 160C238.3 160 224 145.7 224 128V107.4C165.7 119.9 119.9 165.7 107.4 224H128C145.7 224 160 238.3 160 256C160 273.7 145.7 288 128 288H107.4C119.9 346.3 165.7 392.1 224 404.6z" />
                  </svg>
                </li>
                <li
                  className={`chat-list-item ${
                    location.pathname === "/cartinhas" ? "active" : ""
                  }`}
                  onClick={() => navigate("/cartinhas")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon-menu"
                    viewBox="0 0 512 512"
                  >
                    <path d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z" />
                  </svg>
                </li>
                <li
                  className={`chat-list-item ${
                    location.pathname === "/quiz" ? "active" : ""
                  }`}
                  onClick={() => navigate("/quiz")}
                >
                  <svg
                    className="icon-menu"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32z" />
                  </svg>
                </li>
                <li
                  className={`chat-list-item ${
                    location.pathname === "/tiro-cara" ? "active" : ""
                  }`}
                  onClick={logout}
                >
                  <svg viewBox="0 0 512 512" className="icon-menu">
                    <path d="M96 480h64C177.7 480 192 465.7 192 448S177.7 416 160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64C177.7 96 192 81.67 192 64S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256C0 437 42.98 480 96 480zM504.8 238.5l-144.1-136c-6.975-6.578-17.2-8.375-26-4.594c-8.803 3.797-14.51 12.47-14.51 22.05l-.0918 72l-128-.001c-17.69 0-32.02 14.33-32.02 32v64c0 17.67 14.34 32 32.02 32l128 .001l.0918 71.1c0 9.578 5.707 18.25 14.51 22.05c8.803 3.781 19.03 1.984 26-4.594l144.1-136C514.4 264.4 514.4 247.6 504.8 238.5z" />
                  </svg>
                </li>
              </span>
            </nav>
          </div>
          {props.children}
        </div>
      </div>
    </MenuContext.Provider>
  );
}
