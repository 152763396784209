import React from "react";

export default function ButtonForm(props) {
  function animation() {
    const button = document.getElementById("button-form");
    button.classList.add("processing");
    button.classList.add("animation");
  }

  React.useEffect(() => {
    if (props.loading) animation();
  }, [props.loading]);

  React.useEffect(() => {
    const button = document.getElementById("button-form");
    if (button.classList.contains("processing") && props.success) {
      button.classList.remove("animation");
      button.classList.add("finish-success");
      if (!props.mantainCheckButton) {
        setTimeout(() => {
          button.classList.remove("processing");
          button.classList.remove("finish-success");
          props.callBack();
        }, 1500);
      }
    }
  }, [props.success]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const button = document.getElementById("button-form");
    if (button.classList.contains("processing") && props.error) {
      button.classList.remove("animation");
      button.classList.remove("processing");
      props.callBack();
    }
  }, [props.error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <button
      className="button-form"
      type={props.type}
      id="button-form"
      style={props.style}
      onClick={props.onClick}
      disabled={!!props.loading}
    >
      <span>{props.placeholder}</span>
      <svg viewBox="0 0 15 13" className="svg-success">
        <polyline points="2 6.5 6 10.5 13 2.5"></polyline>
      </svg>
    </button>
  );
}
