export default function errorHandler(error) {
    const { request, response } = error
    if (response) {
        const { erro } = response.data
        const { status } = response
        return {
            erro,
            status,
        }
    }
    if (request) {
        return {
            erro: 'Server time out',
            status: 503,
        }
    }
    return {
        erro: 'opps! verifique a sua conexão com a internet',
    }
}
