import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import './scss/index.scss'
import { Routes } from './routes'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter } from 'react-router-dom'
import { System } from './views'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // na versão 18^ do React o useEffect renderiza duas vezes com o StrictMode
  // <React.StrictMode>
  <>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
    <System />
    <ToastContainer />
  </>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
