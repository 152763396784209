import React from "react";

export default function InputCode(props) {
    function anime() {
        const input = document.getElementById('input-code')
        input.classList.add("on-focus");
    }

    function removeAnime(id) {
        const input = document.getElementById('input-code')
        input.addEventListener("blur", (e) => {
            if (!e.target.value || e.target.value === "-1") {
                input.classList.remove("on-focus");
            }
        });
    }

    React.useEffect(() => {
        anime()
    }, [])

    return (
        <div className="flex-container">
            <div className="input-container">
                <input
                    maxLength={4}
                    minLength={4}
                    id='input-code'
                    className="input-field"
                    onFocus={() => anime()}
                    onBlur={() => removeAnime()}
                    {...props}
                    type="text"
                />
                <div className="input-label">
                    <p>Código</p>
                </div>
            </div>
            <div className="icon-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon">
                    <path d="M256-.0078C260.7-.0081 265.2 1.008 269.4 2.913L457.7 82.79C479.7 92.12 496.2 113.8 496 139.1C495.5 239.2 454.7 420.7 282.4 503.2C265.7 511.1 246.3 511.1 229.6 503.2C57.25 420.7 16.49 239.2 15.1 139.1C15.87 113.8 32.32 92.12 54.3 82.79L242.7 2.913C246.8 1.008 251.4-.0081 256-.0078V-.0078zM256 444.8C393.1 378 431.1 230.1 432 141.4L256 66.77L256 444.8z"/>
                </svg>
            </div>
        </div>
    )
}