import React from "react";

export default function InputName(props) {
    function anime() {
        const input = document.getElementById('input-name')
        input.classList.add("on-focus");
    }

    function removeAnime(id) {
        const input = document.getElementById('input-name')
        input.addEventListener("blur", (e) => {
            if (!e.target.value || e.target.value === "-1") {
                input.classList.remove("on-focus");
            }
        });
    }

    React.useEffect(() => {
        anime()
    }, [])

    return (
        <div className="flex-container">
            <div className="input-container">
                <input
                    id='input-name'
                    className="input-field"
                    onFocus={() => anime()}
                    onBlur={() => removeAnime()}
                    {...props}
                    type={"text"}
                    style={props.style}
                />
                <div className="input-label">
                    <p>Nome</p>
                </div>
            </div>
            <div className="icon-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="icon">
                  <path d="M512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96C576 60.65 547.3 32 512 32zM176 128c35.35 0 64 28.65 64 64s-28.65 64-64 64s-64-28.65-64-64S140.7 128 176 128zM272 384h-192C71.16 384 64 376.8 64 368C64 323.8 99.82 288 144 288h64c44.18 0 80 35.82 80 80C288 376.8 280.8 384 272 384zM496 320h-128C359.2 320 352 312.8 352 304S359.2 288 368 288h128C504.8 288 512 295.2 512 304S504.8 320 496 320zM496 256h-128C359.2 256 352 248.8 352 240S359.2 224 368 224h128C504.8 224 512 231.2 512 240S504.8 256 496 256zM496 192h-128C359.2 192 352 184.8 352 176S359.2 160 368 160h128C504.8 160 512 167.2 512 176S504.8 192 496 192z"/></svg>
            </div>
        </div>
    )
}