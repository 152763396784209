import { useEffect, useMemo, useState } from "react";

const SECOND = 1_000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export default function useTimer(deadline) {
  const parsedDeadline = useMemo(() => deadline, [deadline]);
  const [time, setTime] = useState(deadline - Date.now());
  useEffect(() => {
    if (parsedDeadline) {
      const interval = setInterval(
        () => setTime(parsedDeadline - Date.now()),
        1000
      );

      return () => clearInterval(interval);
    }
  }, [parsedDeadline]);

  return {
    days: Math.floor(time / DAY),
    hours: Math.floor((time / HOUR) % 24),
    minutes: Math.floor((time / MINUTE) % 60),
    seconds: Math.floor((time / SECOND) % 60),
  };
}

export function useRegressiveTimer(timer) {
  let [time, setTime] = useState(timer);
  useEffect(() => {
    const interval = setInterval(
      () =>
        setTime((oldState) => {
          if (oldState > 0) {
            oldState -= 1;
          }
          return oldState;
        }),
      1000
    );

    return () => clearInterval(interval);
  }, [timer]);

  return {
    seconds: time,
  };
}
