export default function Collapse(props) {
    return (
        <ul className="m-d expand-list">
            <li data-md-content="300">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'

                    }}
                >
                    <label name="tab" htmlFor="tab2" tabIndex="-1" className="tab_lab" role="tab">
                        {props.placeholder}
                    </label>
                    {props.value ? 
                        <label className="jailTimer">{props.value}</label>
                    : null }
                    <svg viewBox="0 0 448 512" className="icon-menu">
                        <path d="M416 352c-8.188 0-16.38-3.125-22.62-9.375L224 173.3l-169.4 169.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25C432.4 348.9 424.2 352 416 352z" />
                    </svg>
                </div>
                <input type="checkbox" className="tab" id="tab2" tabIndex="0" />
                <span className="open-close-icon"><i className="fas fa-plus"></i><i className="fas fa-minus"></i></span>
                <div className="content">
                    {props.children}
                </div>
            </li>
        </ul>
    )
}