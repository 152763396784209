import useErrorBoundary from 'use-error-boundary'
import { Navigate, useLocation } from 'react-router-dom'
import { Error500 } from '../../views'
import { isAuthenticated } from '../../service'

export default function PublicRoute(props) {
    const location = useLocation()
    const { ErrorBoundary, reset } = useErrorBoundary({
        onDidCatch: error => {
            if (/Loading chunk [\d]+ failed/.test(error.message))
                window.location.reload()
        }
    })

    return (
        <ErrorBoundary
            render={() => {
                return !isAuthenticated() ? (
                    <div>
                        {props.children}
                    </div>
                ) : (
                    <Navigate to='/chat' state={{ from: location }} replace />
                )
            }}
            renderError={() => <Error500 reset={reset} />}
        />
    )

}