
export default function Timer({ display, showLabel }) {
  return (
    <div style={{ display: "flex" }}>
      {Object.entries(display).map(([label, value], index) => {
        return (
          <div key={label}>
            <div style={{ padding: "1rem", textAlign:'center', paddingLeft: index === 0 ? '0' : '1rem' }}>
              <p
                style={{
                  fontSize: "2rem",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                {`${Math.floor(isNaN(value) ? 0 : value)}`.padStart(2, "0")}
              </p>
              {showLabel && <span>{label}</span>}
            </div>
          </div>
        );
      })}
    </div>
  );
}
