import React from "react"

export default function ButtonSecondary(props) {
    return (
        <button
            className="button-secondary"
            type={props.type}
            onClick={props.onClick}
            style={props.style}
        >
            <span>{props.placeholder}</span>
        </button>
    )
}