export default function LoaderPage(props) {
    if (props.error) {
        return (
            <div>
                Ops! Ocorreu um erro{' '}
                <button
                    type='button'
                    onClick={props.retry}
                >
                    Tente Novamente
                </button>
            </div>
        )
    }
    if (props.pasDelay) {
        return (
            <div>
                Loading...
            </div>
        )
    }
    return <></>
}