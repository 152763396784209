import { string, object, ref } from 'yup'

const registerSchema = object().shape({
    name: string()
        .required('Preencha o campo com seu nome')
        .matches(/^[a-zA-Z ]*$/, 'O nome não deve conter números ou caracteres especiais'),
    email: string()
        .required('Preencha o campo com seu e-mail')
        // .matches(/^((?!\.)[\w_.]*[^.])(@ghbranding)(\.\w+(\.\w+)?[^.\W])$/, "O email deve conter o domínio @ghbranding")
        .matches(/^[a-zA-Z0-9_.-]+(?!@)*$/, 'O email está incorreto'),
    password: string()
        .required('Preencha o campo com sua senha')
        .min(6, 'A senha deve conter no mínimo 6 dígitos'),
    confirmPassword: string()
        .required('Preencha o campo com sua senha')
        .min(6, 'A senha deve conter no mínimo 6 dígitos')
        .oneOf([ref('password'), null], 'As senhas devem coincidir'),
})

export default registerSchema

