export default function Modal(props) {
    return (
        <div className="content">
            <input type="radio" name="modal" value="open" id="modal-open" className="modal-radio" checked={props.open} onChange={() => { }}/>
            <div className="modal">
                <label className="modal-label overlay">
                    <input type="radio" name="modal" value="close" className="modal-radio" onClick={props.close} />
                </label>
                <div className="content">
                    <div className="top">
                        <h2>{props.header}</h2>
                        <label className="modal-label close-btn">
                            <input type="radio" name="modal" value="close" className="modal-radio" onClick={props.close} />
                        </label>
                    </div>
                    {props.children}
                    <div className="footer">
                        {props.footer}
                    </div>
                </div>
            </div>
        </div>
    )
}