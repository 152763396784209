import axios from 'axios'
import { getToken, logout } from './auth'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/api` : 'http://localhost:3000'
})

api.interceptors.request.use(
    async (config) => {
        const token = getToken()

        if (token && config.headers) config.headers.Authorization = `Bearer ${token}`

        return config
    },
    error => Promise.reject(error)
)

api.interceptors.response.use(
    response => response,
    function err(error) {
        const token = getToken()
        if (error.response.status === 401 && token) logout()
        return Promise.reject(error)
    }

)

export default api