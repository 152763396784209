import { object, string, ref } from 'yup';

const firstAccessSchema = object().shape({
    password: string()
        .required('Preencha o campo com sua senha')
        .min(6, 'A senha deve conter no mínimo 6 dígitos'),
    confirmPassword: string()
        .when('password', (password, schema) => {
            if (password) return schema.required('Preencha o campo com sua senha');
        })
        .oneOf([ref('password')], 'As senhas não conferem')
})

export default firstAccessSchema
