import { string, object, ref } from 'yup'

const changePassSchema = object().shape({
    password: string()
        .required('Preencha o campo com sua senha')
        .min(6, 'A senha deve conter no mínimo 6 dígitos'),
    confirmPassword: string()
        .required('Preencha o campo com sua senha')
        .min(6, 'A senha deve conter no mínimo 6 dígitos')
        .oneOf([ref('password'), null], 'As senhas devem coincidir'),
})

export default changePassSchema
