import React, { Suspense } from "react";
import { LoaderPage } from "../componentes";

export function System() {
  const Screen = React.lazy(() => import("./system/System"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Screen />
    </Suspense>
  );
}
export function Config() {
  const Screen = React.lazy(() => import("./config/Config"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Screen />
    </Suspense>
  );
}
export function Home() {
  const Screen = React.lazy(() => import("./home/Home"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Screen />
    </Suspense>
  );
}
export function FirstAccess() {
  const Screen = React.lazy(() => import("./auth/FisrtAccess"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Screen />
    </Suspense>
  );
}
export function Login() {
  const Screen = React.lazy(() => import("./auth/Login"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Screen />
    </Suspense>
  );
}
export function RecoverPass() {
  const Screen = React.lazy(() => import("./auth/RecoverPass"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Screen />
    </Suspense>
  );
}
export function ChangePass() {
  const Screen = React.lazy(() => import("./auth/ChangePass"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Screen />
    </Suspense>
  );
}
export function Register() {
  const Screen = React.lazy(() => import("./auth/Register"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Screen />
    </Suspense>
  );
}
export function ConfirmCode() {
  const Screen = React.lazy(() => import("./auth/ConfirmCode"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Screen />
    </Suspense>
  );
}
export function Chat() {
  const Chat = React.lazy(() => import("./chat/Chat"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Chat />
    </Suspense>
  );
}

export function Game() {
  const Game = React.lazy(() => import("./game/Game"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Game />
    </Suspense>
  );
}

export function Inbox() {
  const Inbox = React.lazy(() => import("./inbox/Inbox"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Inbox />
    </Suspense>
  );
}

export function Inboxmessage() {
  const Inboxmessage = React.lazy(() => import("./inboxmessage/Inboxmessage"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Inboxmessage />
    </Suspense>
  );
}
export function Quizz() {
  const Screen = React.lazy(() => import("./quizz/Quizz"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Screen />
    </Suspense>
  );
}
export function Error403() {
  const Screen = React.lazy(() => import("./error/Error403"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Screen />
    </Suspense>
  );
}
export function Error404() {
  const Screen = React.lazy(() => import("./error/Error404"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Screen />
    </Suspense>
  );
}
export function Error500() {
  const Screen = React.lazy(() => import("./error/Error500"));
  return (
    <Suspense fallback={(props) => <LoaderPage {...props} />}>
      <Screen />
    </Suspense>
  );
}
